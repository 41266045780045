/* General */

html, body, #app, #app>div {
  height: 100%
}

.App {
  text-align: center;
}

#particle-js {
  position:fixed !important; 
  height: 100vh;
  width: 100%;
  z-index: -100;
  top: 0;
  left: 0;
}

.ContainerStyle {
  margin: auto;
  min-height: 100vh; 
}

.hidden {
  display: none; 
}

.marginTop {
  margin-top: 10px; 
}



.NavbarText {
  font-family: DMSans-Medium;
  font-size: 1.5em; 
  color: black; 
}

.NavbarText:Hover {
  color:rgb(33, 150, 243)
}


/* Landing Page  */


.Headshot {
  opacity: 0;
  animation: fadeIn 2.5s ease-in-out 0s forwards;
}

.LandingPageContentL {
  position: absolute; 
  padding-top: 5em; 
}

/* Experience */

.TimelineStyle {
  color: black !important; 
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: rgb(33, 150, 243);
}

/* Projects */

.technologiesText {
  font-family: DMSans-Medium;
  font-size: 1em; 
  color: gray; 
}

.projectDescriptText {
  font-family: DMSans-Medium;
  font-size: 1.1em; 
}

/* About Me */



/* MEDIA QUERIES */

@media only screen and (min-width: 1px) {
  .HeaderTextSm {
    font-family: DMSans-Medium;
    font-size: 1.5em;
    opacity: 0; 
    animation: fadeInUp 2s ease-in-out 0s forwards;
    margin: 0px !important; 
  }

  .HeaderTextLgB {
    font-family: DMSans-Bold;
    font-size: 3em;
    opacity: 0; 
    animation: fadeInUp 2s ease-in-out 0s forwards;
    margin: 0px !important; 
  }

  .HeaderTextMB {
    font-family: DMSans-Bold;
    font-size: 1.5em; 
    opacity: 0;
    animation: fadeInDown 2s ease-in-out 0s forwards;
    margin: 0px !important;
  }

  .IconRow {
    margin: 15px; 
    opacity: 0;
    animation: fadeInUp 2s ease-in-out 0s forwards;
    animation-delay: 2s;
    font-size: 1.75em;
  }

  .titleText {
    font-family: DMSans-Bold;
    font-size: 1.5em; 
  }

  .subtitleText {
    font-family: DMSans-Bold;
    font-size: 1.25em; 
  }

  .BodyText {
    font-family: DMSans-Medium;
    font-size: 1em;
    opacity: 0;
    animation: fadeInDown 2.5s ease-in-out 0s forwards;
    animation-delay: 2s; 
  }

  .bulletStyle {
    margin-left: 10px; 
    margin-top: 2px;
  }

  .AboutText {
    font-family: DMSans-Medium;
    font-size: 1.1em 
  }

  .skillCardStyle {
    margin-bottom: 30px;
  }

  .skillText {
    font-family: DMSans-Medium;
    font-size: .75em; 
    display: inline-block;
    margin-left: 10px;
  }

  .AboutDescript {
    font-family: DMSans-Medium;
    font-size: 1em; 
  }

  .UpArrow {
    display: none; 
  }
}

@media only screen and (min-width:350px) {
  .IconRow {
    margin: 30px; 
    opacity: 0;
    animation: fadeInUp 2s ease-in-out 0s forwards;
    animation-delay: 2s;
    font-size: 1.75em;
  }

  .titleText {
    font-family: DMSans-Bold;
    font-size: 2em; 
  }

  .subtitleText {
    font-family: DMSans-Bold;
    font-size: 1.5em; 
  }

  .HeaderTextSm {
    font-family: DMSans-Medium;
    font-size: 2em;
    opacity: 0; 
    animation: fadeInUp 2s ease-in-out 0s forwards;
    margin: 0px !important; 
  }

  .HeaderTextLgB {
    font-family: DMSans-Bold;
    font-size: 5em;
    opacity: 0; 
    animation: fadeInUp 2s ease-in-out 0s forwards;
    margin: 0px !important; 
  }

  .HeaderTextMB {
    font-family: DMSans-Bold;
    font-size: 2.5em; 
    opacity: 0;
    animation: fadeInDown 2s ease-in-out 0s forwards;
    margin: 0px !important;
  }

  .BodyText {
    font-family: DMSans-Medium;
    font-size: 1.5em;
    opacity: 0;
    animation: fadeInDown 2.5s ease-in-out 0s forwards;
    animation-delay: 2s; 
  }

  .bulletStyle {
    margin-left: 30px; 
    margin-top: 2px;
  }

  .skillCardStyle {
    margin-bottom: none;
  }

  .skillText {
    font-family: DMSans-Medium;
    font-size: 1em; 
    display: inline-block;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 426px) {
  .bottomCard {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 430px) {
  .IconRow {
    margin: 30px; 
    opacity: 0;
    animation: fadeInUp 2s ease-in-out 0s forwards;
    animation-delay: 2s;
    font-size: 2.5em;
  }

  .AboutText {
    font-family: DMSans-Medium;
    font-size: 1.5em 
  }
  .AboutDescript {
    font-family: DMSans-Medium;
    font-size: 1.25em; 
  }
  
}

@media only screen and (min-width: 771px) {
  .skillText {
    font-family: DMSans-Medium;
    font-size: 1.25em; 
    display: inline-block;
    margin-left: 55px;
  }

  .iconStyle {
    font-size: 2em; 
    color: black !important; 
  }

  .AboutDescript {
    font-family: DMSans-Medium;
    font-size: 1.5em; 
  }
}

@media only screen and (max-width: 770px){
  .iconStyle {
    font-size: 1.5em; 
    color: black !important; 
  }
}




@media only screen and (max-width: 1023px){
  .LandingPageContentR {
    display: none; 
  }

  /* .UpArrow {
    display: none; 
  } */
}

@media only screen and (min-width: 1025px){
  .LandingPageContentR {
    padding-top: 3em; 
    padding-left: 3em;
  }

  .UpArrow {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    font-size: 50px;
  }
}

@media only screen and (max-width: 1025px) {
  .buttonStyle {
    margin: 10px;
  }
}

@media only screen and (min-width: 1440px) {
  .UpArrow {
    display: block; 
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    font-size: 50px;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1; 
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,100%,0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; 
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,-80%,0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; 
  }
}























